.public-home {
    .card {
        width: 22rem;
        border-radius: 8px;
    }
    .depth {
        background-color: #f0f0f0;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    }  
    .contact-us {
        footer{
            height:50px;
            line-height:50px;
            bottom:0;
            left:0;
            width:100%;
          }
        img {
            width: 100%;
            border-radius: 8px;
        }
        .list-group {
            border-radius: 8px;
        }
    }    
    a {
        text-decoration: none;
        color: #000000;
        .icons-details {
            color: #113b63;
            font-size: 1.5rem;
        }
    }
    .auth {
        background: #103d67;
        color: #ffffff;
    }
}