.ratings {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center; 
      .rate {
        // height: 6px;
        padding: 0 0px;
      }
      .rate:not(:checked) > input {
        position: absolute;
        top: -9999px;
      }
      .rate:not(:checked) > label {
        float: right;
        width: 0.7em;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: 24px;
        color: #ccc;
      }
      .rate:not(:checked) > label:before {
        content: "★ ";
      }
      .rate > input:checked ~ label {
        color: #ffc700;
      }
      .rate:not(:checked) > label:hover,
      .rate:not(:checked) > label:hover ~ label {
        color: #deb217;
      }
      .rate > input:checked + label:hover,
      .rate > input:checked + label:hover ~ label,
      .rate > input:checked ~ label:hover,
      .rate > input:checked ~ label:hover ~ label,
      .rate > label:hover ~ input:checked ~ label {
        color: #c59b08;
      }
      
      /* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */
        
}