.chats {
  li {
    padding: 1rem 1rem 0.5rem 1rem;
  }
  a {
    text-decoration: none;
  }
  .profile-picture-chats {
    .banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 1rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        box-sizing: border-box;
    }
    .banner img {
        background-color: #fff;
        width: 2.9rem;
        height: 2.9rem;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
        border-radius: 50%;
        transform: translateY(50%);
        transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        object-fit: cover;
    }
    .banner img:hover {
        transform: translateY(50%) scale(1.3);
    }
  }
}

