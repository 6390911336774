.login {
  input {
    border: 0;
    font: inherit;
    background: #ff4d4d;
  
    &::placeholder {
      color: #ffffff;
    }
  }
  
  .grid {
    margin: 0 auto;
    max-width: 25rem;
    width: 100%;
  }
  
  h2 {
    font-size: 2.75rem;
    font-weight: 100;
    margin: 0 0 1rem;
    text-transform: uppercase;
  }
  
  .email, .password, .button {
    padding: 0.5rem 1rem;
  }
  
  .email {
    border-radius: 9px 9px 0px 0px;
  }
  .password {
    border-radius: 0px 0px 9px 9px;
  }
  .button {
    border-radius: 8px;
    margin: 1rem;
    background: #FFFFFF;
    width: 65%;
    color: #FF0000;
  }
  
  .register {
    box-shadow: 0 0 250px #000;
    text-align: center;
    padding: 4rem 2rem;
    border-radius: 9px;
    background: #be1625 !important; 
  }
  
  .thick-letter {
    font-weight: bold;
  }
  .text-color {
    color: #FFFFFF !important;
  }
  
  a {
    text-decoration: none !important;
    color: #FFFFFF !important;
  }
  .site__logo {
    width: 100%;
  }
  .register-section {
    border-top: 2px solid #ffffff; 
  }
}