.freight-records {
    padding-bottom: 1rem;
    border-bottom: 3px solid #FF0000;

    .chat-with, .call {
        color: #000000;
    }
    .title {
        background: #FF0000 !important;
        color: #ffffff !important;
        font-weight: bold;
    }
    .list-group-item {
        color: #000000;
    }
    .badge {
        color: #000000;
    }
    .text-color {
        color: #7c7878;
    }

    a {
        text-decoration: none !important;
    }
    .right-align {
        float: right !important;
    }      
    
    .icon-details {
        margin: 0 6px;
        font-size: 2rem;
    }
    .icon-finished {
        color: green;
        font-size: 2rem;
    }

    .pencil-color {
        color: rgb(0, 21, 255);
    }

    .trash-color {
        color: #FF0000;
    }

    a {
        text-decoration: none;
    }

    .react-tabs__tab--selected {
        background: #FF0000;
        color: #ffffff;
    }
}

.profile-picture {
    padding-top: 0.5rem;
    .banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 1rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        box-sizing: border-box;
    }
    .banner img {
        background-color: #fff;
        width: 2.9rem;
        height: 2.9rem;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
        border-radius: 50%;
        transform: translateY(50%);
        transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        object-fit: cover;
    }
    .banner img:hover {
        transform: translateY(50%) scale(1.3);
    }
}