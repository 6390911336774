.trips-made {
  .list-transactions {
    max-height: 300px;
    overflow-y: auto;
  }
  span {
    color: #000000 !important;
  }
  a {
    text-decoration: none;
  }
  li {
    border-radius: 8px !important;
  }
  .icons-details {
    font-size: 3rem;
  }
}